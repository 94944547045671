import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      id="katman_1"
      xmlns="http://www.w3.org/2000/svg"
      // width="64"
      // height="24"
      viewBox="0 0 64 64"
      version="1.1"
      {...props}
    >
      <path
        d="M 22.173 1.484 C 4.440 7.018, -4.848 27.992, 2.790 45.258 C 5.401 51.160, 12.757 58.539, 18.804 61.320 C 25.480 64.391, 38.553 64.375, 45.268 61.289 C 51.689 58.336, 59.386 50.269, 61.494 44.280 C 62.420 41.651, 63.587 38.719, 64.089 37.764 C 65.264 35.525, 65.282 24.708, 64.110 25.432 C 63.620 25.735, 62.937 24.476, 62.591 22.635 C 61.698 17.873, 56.503 10.476, 51.218 6.441 C 43.332 0.419, 31.860 -1.539, 22.173 1.484 M 18 20.500 C 18 24.333, 18.296 25, 20 25 C 21.333 25, 22 24.333, 22 23 C 22 21.190, 22.667 21, 29 21 C 32.850 21, 36 21.164, 36 21.365 C 36 21.566, 32.617 24.529, 28.482 27.949 C 20.058 34.918, 18 38.059, 18 43.950 L 18 48 31.500 48 L 45 48 45 43.500 C 45 39.367, 44.796 39, 42.500 39 C 40.667 39, 40 39.533, 40 41 C 40 42.843, 39.333 43, 31.500 43 C 24.144 43, 23 42.770, 23 41.288 C 23 39.634, 31.623 30.893, 37.250 26.843 L 40 24.864 40 29.432 C 40 33.638, 40.198 34, 42.500 34 C 44.981 34, 45 33.933, 45 25 L 45 16 31.500 16 L 18 16 18 20.500 M 0.320 32 C 0.320 35.575, 0.502 37.038, 0.723 35.250 C 0.945 33.462, 0.945 30.538, 0.723 28.750 C 0.502 26.962, 0.320 28.425, 0.320 32"
        stroke="none"
        fill="#045444"
        fillRule="evenodd"
      />
      <path
        d="M 18 20.500 C 18 24.333, 18.296 25, 20 25 C 21.333 25, 22 24.333, 22 23 C 22 21.190, 22.667 21, 29 21 C 32.850 21, 36 21.164, 36 21.365 C 36 21.566, 32.617 24.529, 28.482 27.949 C 20.058 34.918, 18 38.059, 18 43.950 L 18 48 31.500 48 L 45 48 45 43.500 C 45 39.367, 44.796 39, 42.500 39 C 40.667 39, 40 39.533, 40 41 C 40 42.843, 39.333 43, 31.500 43 C 24.144 43, 23 42.770, 23 41.288 C 23 39.634, 31.623 30.893, 37.250 26.843 L 40 24.864 40 29.432 C 40 33.638, 40.198 34, 42.500 34 C 44.981 34, 45 33.933, 45 25 L 45 16 31.500 16 L 18 16 18 20.500"
        stroke="none"
        fill="#fcfcfc"
        fillRule="evenodd"
      />
    </Svg>
  )
}

export default Icon
